<template>
  <div
    class="modal right fade"
    id="modalAddMember"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Man Power</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group">
              <label>Level di Project : </label>
              <Select2
                v-model="levelProject"
                id="levelProject"
                :class="{ 'is-invalid': formErrorAnggota.level }"
                :options="optionLevel"
                placeholder="Pilih Level di Project"
                @change="mySelectEvent($event, 'levelProject')"
                @select="mySelectEvent($event, 'levelProject')"
              />
              <div class="form-error" v-if="formErrorAnggota.level">
                Inputan ini harus anda isi
              </div>
            </div>

            <div class="form-group">
              <label>Fee</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Rp</span>
                </div>
                <input
                  type="text"
                  class="form-control text-right"
                  v-model.lazy="feeLevel"
                  :class="{ 'is-invalid': formErrorAnggota.fee }"
                  v-money="configMoney"
                  @change="mySelectEvent($event, 'feeLevel')"
                />
                <div class="input-group-append">
                  <span class="input-group-text">/Orang</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Frekuensi</label>
              <input
                type="text"
                v-model="manPowerFrq"
                placeholder="Masukan frekuensi"
                v-maska="'################'"
                class="form-control"
              />
            </div>
            <hr />

            <div class="d-flex mb-3 justify-content-between">
              <input
                type="text"
                class="form-control"
                v-model="filterKaryawan.search"
                placeholder="Masukkan Nama"
                style="max-width: 200px"
              />

              <Select2
                v-model="filterKaryawan.jabatan"
                :options="optionPosition"
                placeholder="Pilih Jabatan"
                style="width: 150px; max-width: 200px"
                @change="myChangeEvent($event)"
                @select="mySelectEvent($event)"
              />

              <Select2
                v-model="filterKaryawan.divisi"
                :options="optionDepartement"
                placeholder="Pilih Departemen"
                style="width: 175px; max-width: 300px"
                @change="myChangeEvent($event)"
                @select="mySelectEvent($event)"
              />

              <button
                class="btn-add icon-search"
                type="button"
                @click="getKaryawan"
                style="width: 50px; max-width: 100px"
              >
                <img src="/img/icon-search.svg" />
              </button>
            </div>

            <div class="table-responsive">
              <div v-if="KaryawanLoad">
                <Skeletor height="50px" class="mb-2 rounded" />
                <Skeletor height="50px" class="mb-2 rounded" />
                <Skeletor height="50px" class="mb-2 rounded" />
              </div>
              <table v-else class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th style="width: 10%" class="text-center">
                      <label style="margin-bottom: 0px">
                        <input
                          @change="selectAll"
                          v-model="anggotaSelectAll"
                          value="1"
                          type="checkbox"
                          name="checkbox"
                        />
                      </label>
                    </th>
                    <th style="width: 40%">Nama</th>
                    <th style="width: 30%">Jabatan</th>
                    <th style="width: 30%">Departemen</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="listKaryawan.length === 0">
                    <td colspan="4" class="text-center">Tidak ada data</td>
                  </tr>
                  <tr
                    v-else
                    v-for="(value, index) in listKaryawan"
                    :key="index"
                    :class="{ 'd-none': value.is_exist }"
                  >
                    <td class="text-center">
                      <label style="margin-bottom: 0px">
                        <input
                          type="checkbox"
                          v-model="listKaryawan[index].checked"
                          :checked="
                            listKaryawan[index].checked === '1' ? true : false
                          "
                          value="1"
                          name="checkbox"
                        />
                      </label>
                    </td>
                    <td>{{ value.nama }}</td>
                    <td>{{ value.jabatan }}</td>
                    <td>{{ value.divisi }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn-save"
            type="button"
            @click="simpanAnggota"
            style="
              background-color: #40ddd4;
              color: #fff;
              font-size: 16px;
              font-weight: 400;
              padding: 10px;
              border-radius: 5px;
              border: none;
              width: auto;
            "
          >
            Tambah Data
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="addAddress"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :style="{ maxWidth: '650px' }"
    >
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">{{ labelModalAddress }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="labelAlamat" class="form-label">Label </label>
                  <input
                    type="text"
                    v-model="locationForm.label"
                    @keyup="formChangeLokasi('lokasi', 'label')"
                    :class="{
                      'is-invalid': formErrorLokasi && formErrorLokasi.label,
                    }"
                    class="form-control"
                    id="labelAlamat"
                    placeholder="Masukkan label..."
                  />
                  <div
                    class="form-error"
                    v-if="formErrorLokasi && formErrorLokasi.label"
                  >
                    {{ formErrorLokasi.label }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="labelAlamat" class="form-label"
                    >Nama Tempat</label
                  >
                  <input
                    type="text"
                    v-model="locationForm.nama"
                    @keyup="formChangeLokasi('lokasi', 'nama')"
                    :class="{
                      'is-invalid': formErrorLokasi && formErrorLokasi.nama,
                    }"
                    class="form-control"
                    id="namaAlamat"
                    placeholder="Masukkan nama..."
                  />
                  <div
                    class="form-error"
                    v-if="formErrorLokasi && formErrorLokasi.nama"
                  >
                    {{ formErrorLokasi.nama }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Provinsi</label>
                  <Select2
                    v-model="locationForm.provinsi"
                    id="provinsi"
                    :options="OptionsProvince"
                    :class="{
                      'is-invalid': formErrorLokasi && formErrorLokasi.provinsi,
                    }"
                    placeholder="Pilih Provinsi"
                    @change="locationEvent($event)"
                    @select="locationEvent($event, 'provinsi')"
                  />
                  <div
                    class="form-error"
                    v-if="formErrorLokasi && formErrorLokasi.provinsi"
                  >
                    {{ formErrorLokasi.provinsi }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Kabupaten</label>
                  <Select2
                    v-model="locationForm.kabupaten"
                    id="kabupaten"
                    :options="OptionsRegion"
                    :class="{
                      'is-invalid':
                        formErrorLokasi && formErrorLokasi.kabupaten,
                    }"
                    placeholder="Pilih Kabupaten"
                    @change="locationEvent($event)"
                    @select="locationEvent($event, 'kabupaten')"
                  />
                  <div
                    class="form-error"
                    v-if="formErrorLokasi && formErrorLokasi.kabupaten"
                  >
                    {{ formErrorLokasi.kabupaten }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Kecamatan</label>
                  <Select2
                    v-model="locationForm.kecamatan"
                    id="kecamatan"
                    :options="OptionsDistrict"
                    placeholder="Pilih Kecamatan"
                    :class="{
                      'is-invalid':
                        formErrorLokasi && formErrorLokasi.kecamatan,
                    }"
                    @change="locationEvent($event)"
                    @select="locationEvent($event, 'kecamatan')"
                  />
                  <div
                    class="form-error"
                    v-if="formErrorLokasi && formErrorLokasi.kecamatan"
                  >
                    {{ formErrorLokasi.kecamatan }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="location_kode">Kode Pos</label>
                  <input
                    type="text"
                    @keyup="formChangeLokasi('lokasi', 'kode_pos')"
                    v-maska="'#####'"
                    v-model="locationForm.kode_pos"
                    :class="{
                      'is-invalid': formErrorLokasi && formErrorLokasi.kode_pos,
                    }"
                    class="form-control"
                    id="location_kode"
                    placeholder="Masukkan kode pos"
                  />
                  <div
                    class="form-error"
                    v-if="formErrorLokasi && formErrorLokasi.kode_pos"
                  >
                    {{ formErrorLokasi.kode_pos }}
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label>Tanggal Pelaksanaan</label>
                  <Datepicker
                    :autoApply="true"
                    v-model="locationForm.tanggal_pelaksanaan"
                    :class="{
                      'is-invalid': formErrorLokasi && formErrorLokasi.tanggal_pelaksanaan,
                    }"
                    :minDate="formData.tanggal_mulai"
                    :maxDate="formData.tanggal_selesai"
                    placeholder="Tanggal Pelaksanaan"
                    :format="formatRange"
                    :range="true"
                    :enableTimePicker="false"
                    locale="id-ID"
                    :disabled="
                      !isEditable || (validated == 1 && labelPage == 'Edit')
                    "
                    selectText="Pilih"
                    cancelText="Batal"
                    @update:modelValue="
                      changeDate($event, 'tanggal_pelaksanaan', 'lokasi')
                    "
                  ></Datepicker>
                  <div
                    class="form-error"
                    v-if="formErrorLokasi && formErrorLokasi.tanggal_pelaksanaan"
                  >
                    {{ formErrorLokasi.tanggal_pelaksanaan }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Alamat</label>
                  <textarea
                    class="form-control"
                    v-model="locationForm.alamat"
                    @keyup="formChangeLokasi('lokasi', 'alamat')"
                    @change="showCoordinate"
                    id="exampleFormControlTextarea1"
                    :class="{
                      'is-invalid': formErrorLokasi && formErrorLokasi.alamat,
                    }"
                    placeholder="Tuliskan detail alamat..."
                    rows="3"
                  ></textarea>
                  <div
                    class="form-error"
                    v-if="formErrorLokasi && formErrorLokasi.alamat"
                  >
                    {{ formErrorLokasi.alamat }}
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12">
                <div class="mapouter">
                  <div
                    class="alert alert-warning mb-2"
                    v-if="locationForm.koordinat"
                  >
                    Pindahkan pin jika lokasi tidak tepat
                  </div>
                  <div
                    id="MapsLocation"
                    style="height: 250px; width: 100%"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="addLocation" class="btn btn-save">
              Tambah Data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelPage }} Project</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-project">Project </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }}
                  </li>
                </ol>
              </nav>
            </div>
            <div></div>

            <div
              class="button-edit"
              v-show="
                validated == 1 &&
                labelPage == 'Edit' &&
                formData.status == 'aktif'
              "
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <div class="detail">
              <div class="form-step">
                <div class="form-step-header">
                  <div
                    class="form-step-number"
                    :class="{ active: stepActive >= 1 }"
                  >
                    <span class="number">1</span>
                  </div>
                  <div
                    class="form-step-number"
                    :class="{ active: stepActive >= 2 }"
                  >
                    <span class="number">2</span>
                  </div>
                  <div
                    class="form-step-number"
                    :class="{ active: stepActive >= 3 }"
                  >
                    <span class="number">3</span>
                  </div>
                </div>
                <div class="form-step-title">
                  <div class="step-title">
                    <span class="title">Detail Project</span>
                  </div>
                  <div class="step-title">
                    <span class="title">Keuangan Project</span>
                  </div>
                  <div class="step-title">
                    <span class="title">Lokasi Project</span>
                  </div>
                </div>
                <div class="form-step-body">
                  <div
                    class="form-step-item"
                    id="detailProject"
                    :class="{ active: stepActive == 1 }"
                  >
                    <div class="my-3 text-right">
                      <button
                        type="button"
                        @click="toStep(2)"
                        class="btn btn-save"
                      >
                        Selanjutnya
                      </button>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Nama Project : </label>
                          <input
                            type="text"
                            class="form-control"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            v-model="formData.nama"
                            @keyup="formChange('nama')"
                            :class="{
                              'is-invalid': formError && formError.nama,
                            }"
                            placeholder="Masukkan Nama Project"
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError.nama"
                          >
                            {{ formError.nama }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Nama Client</label>
                          <Select2
                            v-model="formData.id_client"
                            :options="optionClient"
                            placeholder="Pilih Nama Client"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            :class="{
                              'is-invalid': formError && formError.id_client,
                            }"
                            @change="formChange('id_client')"
                            @select="formChange('id_client')"
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError.id_client"
                          >
                            {{ formError.id_client }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Nama Departemen</label>
                          <Select2
                            v-model="formData.id_divisi"
                            :options="optionDepartementWithoutAll"
                            placeholder="Pilih Nama Departemen"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            :class="{
                              'is-invalid': formError && formError.id_divisi,
                            }"
                            @change="formChange('id_divisi')"
                            @select="formChange('id_divisi')"
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError.id_divisi"
                          >
                            {{ formError.id_divisi }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-12 col-sm-6"
                        v-if="this.formData.id_divisi"
                      >
                        <div class="form-group">
                          <label>Penomoran Project</label>
                          <Select2
                            v-model="formData.id_penomoran"
                            :options="optionNumbering"
                            placeholder="Pilih Penomoran"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            :class="{
                              'is-invalid': formError && formError.id_penomoran,
                            }"
                            @change="formChange('id_penomoran')"
                            @select="formChange('id_penomoran')"
                            :settings="{ templateResult: formatState }"
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError.akun_pendapatan"
                          >
                            {{ formError.akun_pendapatan }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>PIC</label>
                          <Select2
                            v-model="formData.pic"
                            :options="optionKaryawan"
                            placeholder="Pilih PIC"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            :class="{
                              'is-invalid': formError && formError.pic,
                            }"
                            @change="formChange('pic', 'detail', $event)"
                            @select="formChange('pic', 'detail', $event)"
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError.pic"
                          >
                            {{ formError.pic }}
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-12 col-sm-3 d-flex align-items-center">
                        <div class="form-group">
                          <label style="margin-bottom: 0px" class="d-flex align-items-center">
                            <input
                              :value="true"
                              v-model="formData.count_fee_marketing"
                              @change="formChange('count_fee_marketing')"
                              type="checkbox"
                            />
                            <div class="ml-2">Hitung Fee Marketing</div>
                        </label>
                        </div>
                      </div> -->
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Marketing</label>
                          <div>
                            <div
                              class="custom-control custom-radio custom-control-inline"
                            >
                              <input
                                type="radio"
                                id="typePm1"
                                v-model="formData.type_pm"
                                value="pm"
                                class="custom-control-input"
                                @change="formChange('type_pm', 'detail')"
                              />
                              <label class="custom-control-label" for="typePm1"
                                >PM</label
                              >
                            </div>
                            <div
                              class="custom-control custom-radio custom-control-inline"
                            >
                              <input
                                type="radio"
                                id="typePm2"
                                v-model="formData.type_pm"
                                value="spm"
                                class="custom-control-input"
                                @change="formChange('type_pm', 'detail')"
                              />
                              <label class="custom-control-label" for="typePm2"
                                >SPM</label
                              >
                            </div>
                            <div
                              class="custom-control custom-radio custom-control-inline"
                            >
                              <input
                                type="radio"
                                id="typePm3"
                                v-model="formData.type_pm"
                                value="non pm"
                                class="custom-control-input"
                                @change="formChange('type_pm', 'detail')"
                              />
                              <label class="custom-control-label" for="typePm3"
                                >NON PM/SPM</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Pilih Marketing</label>
                          <Select2
                            v-model="formData.project_marketing"
                            :options="optionKaryawan"
                            placeholder="Daftar Karyawan"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit') ||
                              (formData.type_pm == 'non pm' && isEditable)
                            "
                            :class="{
                              'is-invalid':
                                formError && formError.project_marketing,
                            }"
                            @change="
                              formChange('project_marketing', 'detail', $event)
                            "
                            @select="
                              formChange('project_marketing', 'detail', $event)
                            "
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError.project_marketing"
                          >
                            {{ formError.project_marketing }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Penanggung Jawab Keuangan</label>
                          <Select2
                            v-model="formData.pic_keuangan"
                            :options="optionKaryawan"
                            placeholder="Daftar Karyawan"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            :class="{
                              'is-invalid': formError && formError.pic_keuangan,
                            }"
                            @change="
                              formChange('pic_keuangan', 'detail', $event)
                            "
                            @select="
                              formChange('pic_keuangan', 'detail', $event)
                            "
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError.pic_keuangan"
                          >
                            {{ formError.pic_keuangan }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Apakah project ini terkena pajak?</label>
                          <div>
                            <div
                              class="custom-control custom-radio custom-control-inline"
                            >
                              <input
                                type="radio"
                                id="withTax1"
                                v-model="formData.with_tax"
                                @change="formChange('with_tax')"
                                value="ya"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label" for="withTax1"
                                >Ya</label
                              >
                            </div>
                            <div
                              class="custom-control custom-radio custom-control-inline"
                            >
                              <input
                                type="radio"
                                id="withTax2"
                                v-model="formData.with_tax"
                                @change="formChange('with_tax')"
                                value="tidak"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label" for="withTax2"
                                >Tidak</label
                              >
                            </div>
                          </div>
                          <!-- <Select2
                            v-model="formData.with_tax"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            :options="[
                              { id: 'tidak', text: 'Tidak' },
                              { id: 'ya', text: 'Ya' },
                            ]"
                            placeholder="Pilih item"
                            :class="{
                              'is-invalid': formError && formError.with_tax,
                            }"
                            @change="formChange('with_tax')"
                            @select="formChange('with_tax')"
                          /> -->
                          <div
                            class="form-error"
                            v-if="formError && formError.with_tax"
                          >
                            {{ formError.with_tax }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Kategori</label>
                          <Select2
                            v-model="formData.id_kategori"
                            :options="listKategori"
                            placeholder="Pilih Nama Kategori"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            :class="{
                              'is-invalid': formError && formError.id_kategori,
                            }"
                            @change="formChange('id_kategori')"
                            @select="formChange('id_kategori')"
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError.id_kategori"
                          >
                            {{ formError.id_kategori }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Jumlah Partisipan : </label>
                          <input
                            type="text"
                            class="form-control"
                            v-maska="'##########'"
                            v-model="formData.partisipan"
                            @keyup="formChange('partisipan')"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            :class="{
                              'is-invalid': formError && formError.partisipan,
                            }"
                            placeholder="Masukkan Jumlah Partisipan"
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError.partisipan"
                          >
                            {{ formError.partisipan }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Tanggal Mulai</label>
                          <Datepicker
                            :autoApply="true"
                            :class="{
                              'is-invalid':
                                formError && formError.tanggal_mulai,
                            }"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            v-model="formData.tanggal_mulai"
                            placeholder="Tanggal Mulai"
                            :format="format"
                            :enableTimePicker="false"
                            locale="id-ID"
                            selectText="Pilih"
                            cancelText="Batal"
                            @update:modelValue="
                              changeDate($event, 'tanggal_mulai')
                            "
                          ></Datepicker>
                          <div
                            class="form-error"
                            v-if="formError && formError.tanggal_mulai"
                          >
                            {{ formError.tanggal_mulai }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Tanggal Berakhir</label>
                          <Datepicker
                            :autoApply="true"
                            v-model="formData.tanggal_selesai"
                            :class="{
                              'is-invalid':
                                formError && formError.tanggal_selesai,
                            }"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            :minDate="formData.tanggal_mulai"
                            :format="format"
                            placeholder="Tanggal Berakhir"
                            :enableTimePicker="false"
                            locale="id-ID"
                            selectText="Pilih"
                            cancelText="Batal"
                            @update:modelValue="
                              changeDate($event, 'tanggal_selesai')
                            "
                          ></Datepicker>
                          <div
                            class="form-error"
                            v-if="formError && formError.tanggal_selesai"
                          >
                            {{ formError.tanggal_selesai }}
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Tanggal Pelaksanaan</label>
                          <Datepicker
                            :autoApply="true"
                            v-model="formData.tanggal_pelaksanaan"
                            :class="{
                              'is-invalid':
                                formError && formError.tanggal_pelaksanaan,
                            }"
                            :minDate="formData.tanggal_mulai"
                            :maxDate="formData.tanggal_selesai"
                            placeholder="Tanggal Pelaksanaan"
                            :format="format"
                            :enableTimePicker="false"
                            locale="id-ID"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            selectText="Pilih"
                            cancelText="Batal"
                            @update:modelValue="
                              changeDate($event, 'tanggal_pelaksanaan')
                            "
                          ></Datepicker>
                          <div
                            class="form-error"
                            v-if="formError && formError.tanggal_pelaksanaan"
                          >
                            {{ formError.tanggal_pelaksanaan }}
                          </div>
                        </div>
                      </div> -->

                      <div class="col-12 col-sm-12">
                        <div class="form-group">
                          <label>Penanggung Jawab Opex & Payment Order</label>
                          <Select2
                            v-model="formData.approval_person"
                            :options="optionKaryawan"
                            :settings="{
                              multiple: true,
                            }"
                            placeholder="Pilih Karyawan"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            :class="{
                              'is-invalid':
                                formError && formError.approval_person,
                            }"
                            @change="
                              formChange('approval_person', 'detail', $event)
                            "
                            @select="
                              formChange('approval_person', 'detail', $event)
                            "
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError.approval_person"
                          >
                            {{ formError.approval_person }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Akun Piutang</label>
                          <Select2
                            v-model="formData.akun_piutang"
                            :options="optionAkunPiutang"
                            placeholder="Pilih Akun Perkiraan"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            :class="{
                              'is-invalid': formError && formError.akun_piutang,
                            }"
                            @change="formChange('akun_piutang')"
                            @select="formChange('akun_piutang')"
                            :settings="{ templateResult: formatState }"
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError.akun_piutang"
                          >
                            {{ formError.akun_piutang }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Akun Pendapatan</label>
                          <Select2
                            v-model="formData.akun_pendapatan"
                            :options="optionAkunPendapatan"
                            placeholder="Pilih Akun Perkiraan"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            :class="{
                              'is-invalid':
                                formError && formError.akun_pendapatan,
                            }"
                            @change="formChange('akun_pendapatan')"
                            @select="formChange('akun_pendapatan')"
                            :settings="{ templateResult: formatState }"
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError.akun_pendapatan"
                          >
                            {{ formError.akun_pendapatan }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Akun Pajak</label>
                          <Select2
                            v-model="formData.akun_pajak"
                            :options="optionAkunPajak"
                            :disabled="
                              formData.with_tax == 'tidak' ||
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            placeholder="Pilih Akun Perkiraan"
                            :class="{
                              'is-invalid': formError && formError.akun_pajak,
                            }"
                            @change="formChange('akun_pajak')"
                            @select="formChange('akun_pajak')"
                            :settings="{ templateResult: formatState }"
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError.akun_pajak"
                          >
                            {{ formError.akun_pajak }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Akun Diskon</label>
                          <Select2
                            v-model="formData.akun_diskon"
                            :options="OptionAccountDicount"
                            placeholder="Pilih Akun Diskon"
                            :class="{
                              'is-invalid': formError && formError.akun_diskon,
                            }"
                            :disabled="
                              !isEditable ||
                              (validated == 1 && labelPage == 'Edit')
                            "
                            @change="formChange('akun_diskon')"
                            @select="formChange('akun_diskon')"
                            :settings="{ templateResult: formatState }"
                          />
                          <div
                            class="form-error"
                            v-if="formError && formError.akun_diskon"
                          >
                            {{ formError.akun_diskon }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-step-item"
                    id="detailKeuangan"
                    :class="{ active: stepActive == 2 }"
                  >
                    <div class="my-3 text-right">
                      <button
                        type="button"
                        @click="toStep(1)"
                        class="btn btn-red mr-2"
                        style="padding: 7px 12px !important;"
                      >
                        Sebelumnya
                      </button>
                      <button
                        type="button"
                        @click="toStep(3)"
                        class="btn btn-save"
                      >
                        Selanjutnya
                      </button>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="mone">Nilai Project</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">Rp</div>
                            </div>
                            <input
                              type="text"
                              class="form-control text-right"
                              id="mone"
                              placeholder="Masukan Nilai Project"
                              @keyup="formChange('nilai_kontrak')"
                              :disabled="true"
                              :class="{
                                'is-invalid':
                                  formError && formError.nilai_kontrak,
                              }"
                              v-model.lazy="formData.nilai_kontrak"
                              v-money="configMoney"
                            />
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.nilai_kontrak"
                          >
                            {{ formError.nilai_kontrak }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="mone">Management Fee</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <select
                                @change="
                                  formChange(
                                    'management_fee_satuan',
                                    '',
                                    $event
                                  )
                                "
                                v-model="formData.management_fee_satuan"
                                class="form-control"
                              >
                                <option value="rp">Rp</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                            <input
                              type="text"
                              class="form-control text-right"
                              id="mone"
                              placeholder="Masukan Management Fee"
                              :disabled="
                                !isEditable ||
                                (validated == 1 && labelPage == 'Edit')
                              "
                              :class="{
                                'is-invalid':
                                  formError && formError.management_fee,
                              }"
                              @keyup="formChange('management_fee')"
                              v-model.lazy="formData.management_fee"
                              v-money="
                                formData.management_fee_satuan == 'rp'
                                  ? configMoney
                                  : configPercent
                              "
                            />
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.management_fee"
                          >
                            {{ formError.management_fee }}
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="mone">Diskon</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <select
                                @change="
                                  formChange('diskon_satuan', '', $event)
                                "
                                v-model="formData.diskon_satuan"
                                class="form-control"
                              >
                                <option value="rp">Rp</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                            <input
                              type="text"
                              class="form-control text-right"
                              id="mone"
                              placeholder="Masukan Diskon"
                              :disabled="
                                !isEditable ||
                                (validated == 1 && labelPage == 'Edit')
                              "
                              :class="{
                                'is-invalid': formError && formError.diskon,
                              }"
                              @keyup="formChange('diskon')"
                              v-model.lazy="formData.diskon"
                              v-money="
                                formData.diskon_satuan == 'rp'
                                  ? configMoney
                                  : configPercent
                              "
                            />
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.diskon"
                          >
                            {{ formError.diskon }}
                          </div>
                        </div>
                      </div> -->

                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="mone">PPN</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">Rp</div>
                            </div>
                            <input
                              type="text"
                              class="form-control text-right"
                              id="mone"
                              placeholder="Masukan PPN"
                              :disabled="
                                formData.with_tax == 'tidak' ||
                                !isEditable ||
                                (validated == 1 && labelPage == 'Edit')
                              "
                              @keyup="formChange('ppn')"
                              :class="{
                                'is-invalid': formError && formError.ppn,
                              }"
                              v-model.lazy="formData.ppn"
                              v-money="configMoney"
                            />
                            <div
                              class="form-error"
                              v-if="formError && formError.ppn"
                            >
                              {{ formError.ppn }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div>
                          <button
                            class="btn btn-import mr-2"
                            :disabled="onUpload"
                            @click="$refs.file.click()"
                            type="button"
                          >
                            <svg
                              v-if="!onUpload"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14 10.5V13.1667C14 13.5203 13.8595 13.8594 13.6095 14.1095C13.3594 14.3595 13.0203 14.5 12.6667 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V10.5"
                                stroke="white"
                                stroke-width="1.33333"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M4.66927 7.16667L8.0026 10.5L11.3359 7.16667"
                                stroke="white"
                                stroke-width="1.33333"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8 10.5L8 2.5"
                                stroke="white"
                                stroke-width="1.33333"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span class="fas fa-spinner fa-spin" v-else></span>
                            Import File
                          </button>
                          <button
                            @click="downloadFormat"
                            type="button"
                            class="btn btn-download mr-2"
                            v-if="!isOpex"
                          >
                            Download Format
                          </button>
                        </div>
                        <div class="table-responsive mt-4">
                          <table
                            class="table table-bordered table-striped table-hover"
                          >
                            <thead>
                              <tr>
                                <th style="width: 30%">Nama Item</th>
                                <th style="width: 10%">QTY</th>
                                <th style="width: 10%">FRQ</th>
                                <th style="width: 10%">Volume</th>
                                <th style="width: 15%">Unit Cost</th>
                                <th style="width: 25%">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="listPE.length == 0">
                                <td colspan="6" class="text-center">
                                  Tidak ada data
                                </td>
                              </tr>
                              <tr v-for="(value, index) in listPE" :key="index">
                                <td
                                  :class="{
                                    depth2: value.sub_level == 1,
                                    depth3: value.sub_level == 2,
                                  }"
                                  :colspan="value.hasChild == 1 ? '6' : '0'"
                                >
                                  {{ value.nama }}
                                </td>
                                <td v-if="value.hasChild == 0">
                                  {{
                                    checkRabSatuanQty(
                                      value.qty,
                                      value.satuan_qty
                                    )
                                  }}
                                </td>
                                <td v-if="value.hasChild == 0">
                                  {{
                                    checkRabSatuanQty(
                                      value.qty_frq,
                                      value.satuan_frq
                                    )
                                  }}
                                </td>
                                <td v-if="value.hasChild == 0">
                                  {{
                                    checkRabSatuanQty(
                                      value.volume,
                                      value.satuan_volume
                                    )
                                  }}
                                </td>
                                <td v-if="value.hasChild == 0">
                                  <div
                                    class="d-flex justify-content-between align-items-center"
                                  >
                                    <span>Rp</span>
                                    <span>{{
                                      value.unit_cost
                                        ? formatMoney(value.unit_cost)
                                        : 0
                                    }}</span>
                                  </div>
                                </td>
                                <td v-if="value.hasChild == 0">
                                  <div
                                    class="d-flex justify-content-between align-items-center"
                                  >
                                    <span>Rp</span>
                                    <span>{{
                                      value.nilai ? formatMoney(value.nilai) : 0
                                    }}</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <input
                          type="file"
                          style="display: none"
                          @change="changeFile"
                          accept=".xlsx"
                          ref="file"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-step-item"
                    id="detailLokasi"
                    :class="{ active: stepActive == 3 }"
                  >
                    <div class="row my-3">
                      <div class="col-6">
                        <button
                          class="btn-add"
                          type="button"
                          @click="modalLocation(false)"
                          v-if="
                            (isEditable &&
                              formData.status == 'aktif' &&
                              labelPage != 'Edit') ||
                            (isEditable &&
                              formData.status == 'aktif' &&
                              validated != 1 &&
                              labelPage == 'Edit')
                          "
                        >
                          + Tambah Lokasi
                        </button>
                      </div>
                      <div class="col-6 text-right">
                        <button
                          type="button"
                          @click="toStep(2)"
                          class="btn btn-red mr-2"
                          style="padding: 7px 12px !important;"
                        >
                          Sebelumnya
                        </button>
                        <button
                          type="button"
                          @click="toStep()"
                          class="btn btn-save"
                          :disabled="isSubmit"
                        >
                          <span
                            class="fas fa-spin fa-spinner"
                            v-if="isSubmit"
                          ></span>
                          Simpan
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        v-for="(value, index) in listLocation"
                        :key="index"
                        class="col-md-12"
                      >
                        <div class="card-address row">
                          <div class="col-md-8 col-sm-7 col-12">
                            <div class="text-address">
                              {{ value.nama }} - {{  value.tanggal_pelaksanaan.length == 2 ? convertDateRange(value.tanggal_pelaksanaan[0], value.tanggal_pelaksanaan[1]) : '' }}
                            </div>
                            <div class="company-title">
                              {{ value.label }}
                            </div>
                            <div class="text-address">
                              {{ value.alamat_full }}
                            </div>
                          </div>

                          <div
                            class="col-md-4 col-sm-5 col-12 d-flex align-items-end justify-content-end"
                          >
                            <button
                              class="btn btn-edit-address"
                              type="button"
                              v-if="
                                (isEditable &&
                                  formData.status == 'aktif' &&
                                  labelPage != 'Edit') ||
                                (isEditable &&
                                  formData.status == 'aktif' &&
                                  validated != 1 &&
                                  labelPage == 'Edit')
                              "
                              @click="
                                modalLocation(
                                  true,
                                  index,
                                  value.label,
                                  value.nama,
                                  value.provinsi,
                                  value.kabupaten,
                                  value.kecamatan,
                                  value.kode_pos,
                                  value.alamat,
                                  value.koordinat,
                                  value.tanggal_pelaksanaan
                                )
                              "
                            >
                              <svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M15.4525 2.625C15.2338 2.625 15.0063 2.7125 14.84 2.87875L13.2388 4.48L16.52 7.76125L18.1212 6.16C18.4625 5.81875 18.4625 5.2675 18.1212 4.92625L16.0737 2.87875C15.8988 2.70375 15.68 2.625 15.4525 2.625ZM12.3025 7.8925L13.1075 8.6975L5.18 16.625H4.375V15.82L12.3025 7.8925ZM2.625 15.0938L12.3025 5.41625L15.5838 8.6975L5.90625 18.375H2.625V15.0938Z"
                                  fill="#F8CE3A"
                                />
                              </svg>
                              Edit
                            </button>
                            <button
                              v-if="
                                (isEditable &&
                                  formData.status == 'aktif' &&
                                  labelPage != 'Edit') ||
                                (isEditable &&
                                  formData.status == 'aktif' &&
                                  validated != 1 &&
                                  labelPage == 'Edit')
                              "
                              class="btn btn-delete-address"
                              type="button"
                              @click="locationDelete(index)"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.5 3L15.5 4H19V6H5V4H8.5L9.5 3H14.5ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9Z"
                                  fill="#FF3D3D"
                                />
                              </svg>
                              Hapus
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="listLocation.length == 0"
                        class="col-12 text-center"
                      >
                        <p style="font-size: 17px; font-style: italic">
                          Tidak ada data
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
    <InvoiceComponent
      :noInvoice="id_invoice"
      :show="showInvoice"
      @hide="() => (showInvoice = false)"
      @reload="getData()"
      :type="'project'"
      :kode_project="formData.kode"
    />
  </div>
</template>

<script>
import NavbarDashboard from "../../../components/Navbar.vue";
import SidebarDashboard from "../../../components/Sidebar.vue";
// import DetailMember from "./Component/DetailMember.vue";
import InvoiceComponent from "../components/invoiceComponents.vue";
// import DetailRAB from './Component/DetailRAB.vue'
// import DetailReport from './Component/DetailReport.vue'
// import DetailPayment from "./Component/DetailPayment.vue";
import { Loader } from "@googlemaps/js-api-loader";
import $ from "jquery";
import { maska } from "maska";
import { ref } from "vue";
import Datepicker from "vue3-date-time-picker";
import Select2 from "vue3-select2-component";
import {
  get_ListKabupaten,
  get_ListKecamatan,
  get_ListProvinsi,
} from "../../../actions/master";
import {
  checkModuleAccess,
  checkRules,
  cksClient,
  showAlert,
} from "../../../helper";
// import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import "moment/locale/id";
import readXlsxFile, { readSheetNames } from "read-excel-file";
import { VMoney } from "v-money";
import { Skeletor } from "vue-skeletor";
import { get_AkunDefault, get_AkunList } from "../../../actions/akun_perkiraan";
import { get_ListClient } from "../../../actions/client";
import {
  get_DepartementList,
  get_DepartementPenomoran,
} from "../../../actions/company/departement";
import { get_ListPosition } from "../../../actions/company/posistion";
import { get_KaryawanList } from "../../../actions/karyawan";
import { get_ProjectDetail, post_ProjectSave } from "../../../actions/project";
import { get_ProjectKategoriList } from "../../../actions/project/kategori";
import { get_ProjectLevelList } from "../../../actions/project/level";
import { DATA } from "../../../constans/Url";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // DetailRAB,
    // DetailReport,
    // DetailPayment,
    Datepicker,
    Skeletor,
    InvoiceComponent,
    // TableLite
  },

  directives: {
    maska,
    money: VMoney,
  },

  data() {
    return {
      validated: 1,
      checkAccess: checkModuleAccess,
      id_company: cksClient().get("_account").id_company,
      isEnableCancel: false,
      statusCompany: [],
      optionLevel: [],
      optionPosition: [],
      optionDepartement: [],
      optionClient: [],
      OptionsRegion: [],
      OptionsProvince: [],
      OptionsDistrict: [],
      optionKaryawan: [],
      isPic: false,
      configMoney: {
        decimal: "",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      configPercent: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      formatMoney(a) {
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
      isEditable: true,
      //FORMDATA
      isSubmit: false,
      onDone: false,
      formData: {
        kode: "",
        id_company: cksClient().get("_account").id_company,
        id_divisi: "",
        id_client: "",
        id_kategori: "",
        partisipan: "",
        management_fee: 0,
        pic: "",
        pic_keuangan: "",
        project_marketing: "",
        count_fee_marketing: false,
        with_tax: "tidak",
        diskon: 0,
        ppn: 0,
        nama: "",
        tanggal_mulai: "",
        tanggal_pelaksanaan: "",
        tanggal_selesai: "",
        nilai_kontrak: "",
        status: "aktif",
        akun_piutang: "",
        akun_pendapatan: "",
        akun_pajak: "",
        akun_diskon: "",
        id_piutang: "",
        type_pm: "non pm",
        status_pembayaran: "unpaid",
        lokasi: [],
        rab: [],
        report: [],
        anggota: [],
        deleteAnggota: [],
        management_fee_satuan: "rp",
        diskon_satuan: "rp",
        listpe: [],
        id_penomoran: "",
        approval_person: [],
        // pengeluaran: [],
      },
      optionAkunPiutang: [],
      optionAkunPendapatan: [],
      optionAkunPajak: [],
      optionNumbering: [],
      formError: [],
      OptionAccountDicount: [],
      rules: [
        {
          nama: {
            required: true,
          },
          tanggal_mulai: {
            required: true,
          },
          tanggal_selesai: {
            required: true,
          },
          pic_keuangan: {
            required: true,
          },
          id_client: {
            required: false,
          },
          nilai_kontrak: {
            required: false,
          },
          id_kategori: {
            required: true,
          },
          partisipan: {
            required: false,
          },
          akun_piutang: {
            required: true,
          },
          akun_pendapatan: {
            required: true,
          },
          akun_pajak: {
            required: true,
          },
          akun_diskon: {
            required: true,
          },
          type_pm: {
            required: true,
          },
          id_penomoran: {
            required: false,
          },
          count_fee_marketing: {
            required: false,
          },
          project_marketing: {
            required: false,
          },
          approval_person: {
            custom: (value) => {
              if (value.length == 0) {
                return "This form is required";
              }

              return "";
            },
          },
        },
        {
          management_fee: {
            required: false,
          },
          management_fee_satuan: {
            required: true,
          },
          diskon: {
            required: false,
          },
          diskon_satuan: {
            required: true,
          },
          ppn: {
            required: false,
          },
        },
      ],
      kodeProject: this.$route.params.kode ? this.$route.params.kode : "",
      labelPage: this.$route.params.kode ? "Edit" : "Tambah",
      nilaiKontrak: "0",
      //LOKASI
      gmapsLoader: "",
      gMaps: "",
      classGoogle: "",
      markerMaps: "",
      locationForm: {
        key: "",
        label: "",
        nama: "",
        provinsi: "",
        kabupaten: "",
        kecamatan: "",
        kode_pos: "",
        tanggal_pelaksanaan: "",
        alamat: "",
        koordinat: "",
      },
      listLocation: [],
      rulesLokasi: {
        label: {
          required: true,
        },
        nama: {
          required: true,
        },
        provinsi: {
          required: true,
        },
        kabupaten: {
          required: true,
        },
        kecamatan: {
          required: true,
        },
        kode_pos: {
          required: false,
        },
        alamat: {
          required: true,
        },
        tanggal_pelaksanaan: {
          required: true,
        },
      },
      formErrorLokasi: [],
      labelModalAddress: "Tambah Lokasi",
      //ANGGOTA
      filterKaryawan: {
        id_company: cksClient().get("_account").id_company,
        id_divisi: "",
        aktif: true,
        divisi: "all",
        jabatan: "all",
        search: "",
      },
      formErrorAnggota: {
        karyawan: false,
        level: false,
      },
      KaryawanLoad: false,
      listKaryawan: [],
      anggotaSelectAll: "",
      listAnggotaProject: [],
      levelProject: "",
      manPowerFrq: "1",
      feeLevel: 0,
      listKategori: [],
      deleteAnggota: [],

      accessDepartement: cksClient().get("_account").akses_departement,
      //stepFrom
      stepActive: 1,
      //PE
      listPE: [],
      schemaPE: {
        "NOMOR ITEM": {
          prop: "id",
          type: String,
          required: true,
        },
        "NAMA ITEM": {
          prop: "nama",
          type: String,
          required: true,
        },
        QTY: {
          prop: "qty",
          required: false,
        },
        "SATUAN QTY": {
          prop: "satuan_qty",
          required: false,
        },
        FRQ: {
          prop: "qty_frq",
          required: false,
        },
        "SATUAN FRQ": {
          prop: "satuan_frq",
          required: false,
        },
        VOLUME: {
          prop: "volume",
        },
        "SATUAN VOLUME": {
          prop: "satuan_volume",
        },
        "UNIT COST": {
          prop: "unit_cost",
          required: false,
        },
        "INCLUDE PPH": {
          prop: "include_pph",
          type: String,
          oneOf: ["YA", "TIDAK"],
        },
        PPH: {
          prop: "pph",
        },
        REMARKS: {
          prop: "remarks",
        },
      },
    };
  },

  created() {
    if (this.labelPage == "Tambah") {
      if (this.accessDepartement) {
        var data = this.accessDepartement.split("|");
        this.formData.id_divisi = data[0];
      }
      this.defaultAkun();
    }
    this.gmapsLoader = new Loader({
      apiKey: process.env.VUE_APP_ROOT_MAPSAPI,
    });
    this.getAkun("2", "optionAkunPiutang");
    this.getAkun("12,16", "optionAkunPendapatan");
    this.getAkun("8,9,14,15,19", "optionAkunPajak");
    this.getAkun("1", "OptionAccountKasBank");
    this.getAkun("14", "OptionAccountDicount");
    this.getAkun("14,15,17", "optionBebanItems");
    this.getAkun("", "OptionAccountEstimated");
    this.getProvinsi();
    this.getClient();
    this.getLevel();
    this.getPosition();
    this.getDepartement();
    this.getKategori();
    this.getKaryawan("pic");
    if (!this.checkAccess("project", "cru_project")) {
      this.isEditable = false;
    }
    if (this.labelPage == "Tambah") {
      this.validated = 2;
    }
  },

  mounted() {
    // $(document).on("select2:open", () => {
    //   setTimeout(() => {
    //     document.querySelector(".select2-search__field").focus();
    //   }, 100);
    // });

    $.fn.modal.Constructor.prototype._enforceFocus = function () {};
    this.gmapsLoader.load().then((google) => {
      this.classGoogle = google;
      this.gMaps = new this.classGoogle.maps.Map(
        document.getElementById("MapsLocation"),
        {
          center: { lat: -6.174680366387271, lng: 106.82700896290929 },
          zoom: 12,
          mapTypeControl: false,
          streetViewControl: false,
          rotateControl: false,
          scaleControl: false,
        }
      );
      this.gMaps.addListener("click", (event) => {
        this.locationForm.koordinat = `${event.latLng.lat()},${event.latLng.lng()}`;
        this.addMarker(event.latLng);
      });
    });
  },

  unmounted() {
    // $(".nav-link").removeClass("active");
    // $("#pills-detail-tab").tab("show");
    $(".tab-pane.fade").removeClass("active show");
    $("#pills-detail-tab").tab("show");
  },

  setup() {
    const date = ref(new Date());
    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  methods: {
    formatRange(date) {
      if (date[0] && date[1]) {
        return this.convertDateRange(date[0], date[1]);
      }
      return "";
    },

    convertDateRange(date_start, date_end) {
      if (date_start &&date_end) {
        var start = moment(date_start);
        var end = moment(date_end);
        if(start.format("YYYY-MM-DD") == end.format("YYYY-MM-DD") && start.isValid()){
          return end.format("DD MMM YYYY");
        } else if (start.format("YYYY-MM") == end.format("YYYY-MM") && start.isValid()) {
          return `${start.format("DD")} s/d ${end.format("DD MMM YYYY")}`;
        } else if (
          start.format("YYYY") == end.format("YYYY") &&
          start.isValid()
        ) {
          return `${start.format("DD MMM")} s/d ${end.format("DD MMM YYYY")}`;
        } else {
          return `${start.format("DD MMM YYYY")} s/d ${end.format(
            "DD MMM YYYY"
          )}`;
        }
      } else {
        return '';
      }
    },
    defaultAkun() {
      var name_id =
        "akun_piutang_project,akun_diskon_project,akun_pajak_project,akun_pendapatan_project";
      get_AkunDefault(
        {
          id_company: this.id_company,
          name_id: name_id,
        },
        (res) => {
          var list = res.list;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              if (key == 0) {
                this.formData.akun_piutang = element;
              } else if (key == 1) {
                this.formData.akun_diskon = element;
              } else if (key == 2) {
                this.formData.akun_pajak = element;
              } else if (key == 3) {
                this.formData.akun_pendapatan = element;
              }
            }
          }
        }
      );
    },

    showButtonSave(data) {
      if (data == 1) {
        this.validated = 1;
      } else {
        this.validated = 2;
      }
    },
    editable() {
      this.validated = 2;
    },
    getData() {
      get_ProjectDetail(
        this.kodeProject,
        (res) => {
          if (res.is_success) {
            var {
              data,
              lokasi,
              report,
              rab,
              anggota,
              nilaiRAB,
              nilaiReport,
              invoice,
              pengeluaran,
              nilaiPengeluaran,
              isNeededAcc,
              nilaiPembayaran,
              isEnableCancel,
            } = res;
            this.isEnableCancel = isEnableCancel;
            this.isNeededAcc = isNeededAcc;
            this.formData = data;
            delete this.formData.created_at;
            delete this.formData.updated_at;
            this.formData.tanggal_mulai =
              data.tanggal_mulai && data.tanggal_mulai != "null"
                ? moment(data.tanggal_mulai).format("YYYY-MM-DD")
                : "";
            this.formData.tanggal_pelaksanaan =
              data.tanggal_pelaksanaan && data.tanggal_pelaksanaan != "null"
                ? moment(data.tanggal_pelaksanaan).format("YYYY-MM-DD")
                : "";
            this.formData.tanggal_selesai =
              data.tanggal_selesai && data.tanggal_selesai != "null"
                ? moment(data.tanggal_selesai).format("YYYY-MM-DD")
                : null;
            this.listLocation = lokasi;
            this.listRAB = rab;
            this.nilaiRAB = nilaiRAB;
            this.nilaiReport = nilaiReport;
            this.listReport = report;
            this.listAnggotaProject = anggota;
            this.optionPenanggungJawab = [];
            this.listInvoice = invoice;
            this.nilaiInvoice = nilaiPembayaran;
            this.listPengeluaran = pengeluaran;
            this.nilaiPengeluaran = nilaiPengeluaran;
            if (data.status != "aktif") {
              this.isEditable = false;
            }
            this.nilaiKontrak =
              data.nilai_kontrak + data.management_fee - data.diskon;
            this.isPic =
              data.pic === cksClient().get("_account").id ? true : false;
            this.optionRAB.push({
              id: "other",
              text: "Nama item baru",
            });
            for (const key in rab) {
              if (Object.hasOwnProperty.call(rab, key)) {
                const element = rab[key];
                this.optionRAB.push({
                  id: element.id,
                  text: element.nama,
                  sub_level: element.sub_level,
                });
              }
            }
            for (const key in this.listAnggotaProject) {
              if (Object.hasOwnProperty.call(this.listAnggotaProject, key)) {
                this.optionPenanggungJawab.push({
                  id: this.listAnggotaProject[key].id_karyawan,
                  text: this.listAnggotaProject[key].nama,
                });
                this.listAnggotaProject[key].jadwal = this.listAnggotaProject[
                  key
                ].jadwal
                  ? this.listAnggotaProject[key].jadwal
                      .split("|")
                      .map((value) => moment(value).format("YYYY-MM-DD"))
                  : [];
              }
            }
          }
        },
        () => {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Data project tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataProject",
              });
            },
            onDismiss: () => {
              this.$router.push({
                name: "DataProject",
              });
            },
          });
        }
      );
    },
    //MASTER
    convertDate(string) {
      return moment(string).format("DD MMM YYYY");
    },
    getClient() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_ListClient(
        {
          id_company: this.id_company,
          id_divisi: id_divisi,
        },
        (res) => {
          const { list } = res;
          this.optionClient = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionClient.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },
    getLevel() {
      get_ProjectLevelList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionLevel = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionLevel.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },
    getPosition() {
      get_ListPosition(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionPosition = [
            {
              id: "all",
              text: "Semua Jabatan",
            },
          ];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionPosition.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },
    getDepartement() {
      get_DepartementList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionDepartement = [
            {
              id: "all",
              text: "Semua Departemen",
            },
          ];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionDepartement.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
          this.optionDepartementWithoutAll = this.optionDepartement;
          this.optionDepartementWithoutAll.splice(0, 1);
        }
      );
    },
    getKategori() {
      get_ProjectKategoriList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.listKategori = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.listKategori.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },
    getAkun(tipe, keyData) {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: tipe,
          id_divisi: id_divisi,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          // console.log(data);
          this[keyData] = data;
        }
      );
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = "";
      if (state.type && state.no) {
        $state = $(
          '<div class="' +
            padding +
            '">' +
            state.text +
            '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
            state.no +
            "<div>" +
            state.type +
            "</div></div></div>"
        );
      } else if (state.no) {
        $state = $(
          '<div class="' +
            padding +
            '">' +
            state.text +
            '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
            state.no +
            "</div></div>"
        );
      } else {
        $state = $('<div class="' + padding + '">' + state.text + "</div>");
      }
      return $state;
    },
    //LOCATION
    addMarker(coordinate) {
      if (this.markerMaps) {
        this.markerMaps.setPosition(coordinate);
        if (typeof coordinate.lat == "function") {
          this.locationForm.koordinat = `${coordinate.lat()},${coordinate.lng()}`;
        } else {
          this.locationForm.koordinat = `${coordinate.lat},${coordinate.lng}`;
        }
      } else {
        if (typeof coordinate.lat == "function") {
          this.locationForm.koordinat = `${coordinate.lat()},${coordinate.lng()}`;
        } else {
          this.locationForm.koordinat = `${coordinate.lat},${coordinate.lng}`;
        }
        this.markerMaps = new this.classGoogle.maps.Marker({
          position: coordinate,
          map: this.gMaps,
          draggable: true,
        });
        this.markerMaps.addListener("dragend", (event) => {
          this.locationForm.koordinat = `${event.latLng.lat()},${event.latLng.lng()}`;
        });
      }
    },
    showCoordinate() {
      var geocoder = new this.classGoogle.maps.Geocoder();
      var address = this.locationForm.alamat;
      if (address) {
        var provinsi = $("#provinsi option:selected").text();
        var kabupaten = $("#kabupaten option:selected").text();
        var kecamatan = $("#kecamatan option:selected").text();
        if (kecamatan) {
          address += `, ${kecamatan}`;
        }
        if (kabupaten) {
          address += `, ${kabupaten}`;
        }

        if (provinsi) {
          address += `, ${provinsi}`;
        }
        geocoder.geocode({ address: address }, (results, status) => {
          if (status === "OK") {
            this.gMaps.setCenter(results[0].geometry.location);
            this.addMarker(results[0].geometry.location);
          }
        });
      }
    },
    modalLocation(
      edit,
      key = "",
      label = "",
      nama = "",
      provinsi = "",
      kabupaten = "",
      kecamatan = "",
      kode_pos = "",
      alamat = "",
      koordinat = "",
      tanggal_pelaksanaan = []
    ) {
      this.locationForm = {
        key: key,
        label,
        nama: nama,
        provinsi: provinsi,
        kabupaten: kabupaten,
        kecataman: kecamatan,
        kode_pos,
        alamat,
        koordinat,
        tanggal_pelaksanaan,
      };
      if (edit) {
        this.locationForm.kecamatan = kecamatan;
        this.labelModalAddress = "Edit Lokasi";
        var ex = koordinat.split(",");
        this.addMarker({ lat: parseFloat(ex[0]), lng: parseFloat(ex[1]) });
        this.gMaps.setCenter({
          lat: parseFloat(ex[0]),
          lng: parseFloat(ex[1]),
        });
        this.getKabupaten(provinsi);
        this.getKecamatan(kabupaten);
      } else {
        this.labelModalAddress = "Tambah Lokasi";
        if (this.markerMaps) {
          this.markerMaps.setMap(null);
        }
      }
      $("#addAddress").modal("show");
    },
    locationDelete(key) {
      this.listLocation.splice(key, 1);
    },
    mySelectEvent(data, key) {
      if (typeof key === "string" && key == "levelProject" && data.id) {
        this.formErrorAnggota.level = false;
      } else if (
        typeof key === "string" &&
        key == "feeLevel" &&
        data.value != "0"
      ) {
        this.formErrorAnggota.fee = false;
      }
    },
    async formChangeLokasi(type, key) {
      if (type == "lokasi") {
        if (this.rulesLokasi[key]) {
          this.rulesLokasi[key].changed = true;
        }
        var check = await checkRules(this.rulesLokasi, this.locationForm);
        this.formErrorLokasi = check.error;
      }
    },
    async getProvinsi() {
      await get_ListProvinsi(
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            arr.push({
              id: list[key].id,
              text: list[key].nama,
            });
          }
          this.OptionsProvince = arr;
        },
        () => {
          this.OptionsProvince = [];
        }
      );
    },
    async getKabupaten(id) {
      await get_ListKabupaten(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            arr.push({
              id: list[key].id,
              text: list[key].nama,
            });
          }
          this.OptionsRegion = arr;
        },
        () => {
          this.OptionsRegion = [];
        }
      );
    },

    async getKecamatan(id) {
      await get_ListKecamatan(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            arr.push({
              id: list[key].id,
              text: list[key].nama,
            });
          }
          this.OptionsDistrict = arr;
        },
        () => {
          this.OptionsDistrict = [];
        }
      );
    },
    locationEvent(val, key) {
      this.formChangeLokasi("lokasi", key);
      if (key === "provinsi") {
        this.OptionsDistrict = [];
        this.OptionsRegion = [];
        this.getKabupaten(val.id);
      } else if (key === "kabupaten") {
        this.OptionsDistrict = [];
        this.getKecamatan(val.id);
      }
    },
    async addLocation() {
      for (const key in this.rulesLokasi) {
        if (Object.hasOwnProperty.call(this.rulesLokasi, key)) {
          this.rulesLokasi[key].changed = true;
        }
      }
      var check = await checkRules(this.rulesLokasi, this.locationForm);
      this.formErrorLokasi = check.error;
      if (check.success) {
        this.locationForm.alamat_full = this.getAlamatFull();
        if (this.locationForm.key >= 0 && this.locationForm.key !== "") {
          this.listLocation[this.locationForm.key] = this.locationForm;
        } else {
          this.listLocation.push(this.locationForm);
        }
        $("#addAddress").modal("hide");
      }
    },
    getAlamatFull() {
      var provinsi = $("#provinsi")
        .find(`option[value="${this.locationForm.provinsi}"]`)
        .text();
      var kabupaten = $("#kabupaten")
        .find(`option[value="${this.locationForm.kabupaten}"]`)
        .text();
      var kecamatan = $("#kecamatan")
        .find(`option[value="${this.locationForm.kecamatan}"]`)
        .text();
      var kode_pos = this.locationForm.kode_pos
        ? this.locationForm.kode_pos
        : "";
      var alamat = `${this.locationForm.alamat},${kecamatan},${kabupaten},${provinsi} ${kode_pos}`;
      return alamat;
    },
    //ANGGOTA
    async getKaryawan(tipe = "") {
      if (tipe == "pic") {
        var id_divisi =
          this.accessDepartement && this.formData.id_divisi
            ? this.formData.id_divisi
            : "";
        get_KaryawanList(
          {
            id_company: this.id_company,
            search: "aktif",
            id_divisi: id_divisi,
          },
          async (res) => {
            var { list } = res;
            this.optionKaryawan = [];
            for (const key in list) {
              if (Object.hasOwnProperty.call(list, key)) {
                this.optionKaryawan.push({
                  id: list[key].id,
                  text: list[key].nama,
                });
              }
            }
          }
        );
      } else {
        this.filterKaryawan.id_divisi =
          this.accessDepartement && this.formData.id_divisi
            ? this.formData.id_divisi
            : "";
        this.KaryawanLoad = true;
        this.filterKaryawan.aktif = true;
        get_KaryawanList(this.filterKaryawan, async (res) => {
          this.KaryawanLoad = false;
          var { list } = res;
          this.listKaryawan = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              list[key].checked = false;
              var check = this.checkAnggota(list[key].id);
              list[key].is_exist = check;
            }
          }
          this.listKaryawan = list;
        });
      }
    },
    ShowModalAnggota() {
      this.levelProject = "";
      this.feeLevel = "";
      this.manPowerFrq = "1";
      this.filterKaryawan.divisi = "all";
      this.filterKaryawan.jabatan = "all";
      this.filterKaryawan.search = "";
      $("#modalAddMember").modal("show");
      this.getKaryawan();
    },
    selectAll() {
      for (const key in this.listKaryawan) {
        if (
          Object.hasOwnProperty.call(this.listKaryawan, key) &&
          this.anggotaSelectAll
        ) {
          this.listKaryawan[key].checked = !this.listKaryawan[key].is_exist
            ? "1"
            : "";
        } else {
          this.listKaryawan[key].checked = "";
        }
      }
    },
    simpanAnggota() {
      var level = $("#levelProject")
        .find(`option[value="${this.levelProject}"]`)
        .text();
      var fee = parseInt(this.feeLevel.toString().split(".").join(""));
      if (level && fee > 0) {
        this.formErrorAnggota.level = false;
        var check = true;
        for (const key in this.listKaryawan) {
          if (Object.hasOwnProperty.call(this.listKaryawan, key)) {
            const element = this.listKaryawan[key];
            if (element.checked) {
              check = true;
              this.listAnggotaProject.push({
                id_karyawan: element.id,
                id_level: this.levelProject,
                level: $("#levelProject")
                  .find(`option[value="${this.levelProject}"]`)
                  .text(),
                nama: element.nama,
                jadwal: [],
                fee: fee,
                seen: false,
                frq: this.manPowerFrq ? this.manPowerFrq : "1",
              });
            }
          }
        }
        if (check) {
          this.formErrorAnggota.karyawan = false;
          this.updateAnggota(this.listAnggotaProject);
          $("#modalAddMember").modal("hide");
        } else {
          this.formErrorAnggota.karyawan = true;
        }
      } else {
        if (!level) {
          this.formErrorAnggota.level = true;
        }
        if (!fee) {
          this.formErrorAnggota.fee = true;
        }
      }
    },
    deleteMenPower(el) {
      this.deleteAnggota.push(el);
    },
    checkAnggota(id) {
      for (const key in this.listAnggotaProject) {
        if (Object.hasOwnProperty.call(this.listAnggotaProject, key)) {
          const element = this.listAnggotaProject[key];
          if (element.id_karyawan === id) {
            return true;
          }
        }
      }
      return false;
    },
    updateAnggota(ev) {
      this.listAnggotaProject = ev;
      this.optionPenanggungJawab = [];
      for (const key in this.listAnggotaProject) {
        if (Object.hasOwnProperty.call(this.listAnggotaProject, key)) {
          const element = this.listAnggotaProject[key];
          this.optionPenanggungJawab.push({
            id: element.id_karyawan,
            text: element.nama,
          });
        }
      }
    },
    //FORMDATA
    changeDate(data, key, tipe = "") {
      if (!tipe) {
        if (key === "tanggal_mulai" && this.formData.tanggal_pelaksanaan) {
          var date = moment(data).unix();
          var pelaksanaan = moment(this.formData.tanggal_pelaksanaan).unix();
          if (pelaksanaan < date) {
            this.rules.tanggal_pelaksanaan.changed = false;
            this.formData.tanggal_pelaksanaan = "";
          }
        }

        if (key === "tanggal_mulai" && this.formData.tanggal_selesai) {
          var datem = moment(data).unix();
          var selesai = moment(this.formData.tanggal_selesai).unix();
          if (selesai < datem) {
            this.rules.tanggal_selesai.changed = false;
            this.formData.tanggal_selesai = "";
          }
        }

        if (key === "tanggal_selesai" && this.formData.tanggal_pelaksanaan) {
          var dateselesai = moment(data).unix();
          var pelaksanaans = moment(this.formData.tanggal_pelaksanaan).unix();
          if (pelaksanaans > dateselesai) {
            this.rules.tanggal_pelaksanaan.changed = false;
            this.formData.tanggal_pelaksanaan = "";
          }
        }
        this.formData[key] = moment(data).format("YYYY-MM-DD");
        this.formChange(key);
      } else if (tipe == "pengeluaran") {
        this.formPengeluaran[key] = moment(data).format("YYYY-MM-DD");
      } else if (tipe == "lokasi") {
        this.locationForm[key] = [
          moment(data[0]).format("YYYY-MM-DD"),
          moment(data[1]).format("YYYY-MM-DD")
        ];
      }
    },
    formatDate(date, format = 'DD MMMM YYYY'){
      if (date) {
        return moment(date).format(format);
      }
      return '';
    },
    async formChange(key, tipe = "") {
      var index = this.stepActive == 1 ? 0 : this.stepActive == 2 ? 1 : 3;
      if (!tipe || tipe == "detail") {
        if (this.rules[index][key]) {
          this.rules[index][key].changed = true;
        }
        var rules = { ...this.rules[index] };
        if (key == "id_divisi") {
          this.formData.id_penomoran = "";
          this.getPenomoran();
        }
        if (key == "management_fee_satuan") {
          if (this.formData.management_fee_satuan == "rp") {
            delete rules["management_fee"].max;
            delete rules["management_fee"].isPercent;
          } else {
            rules["management_fee"].max = 100;
            rules["management_fee"].isPercent = true;
          }
        } else if (key == "diskon_satuan") {
          if (this.formData.diskon_satuan == "rp") {
            delete rules["diskon"].max;
            delete rules["diskon"].isPercent;
          } else {
            rules["diskon"].max = 100;
            rules["diskon"].isPercent = true;
          }
        }

        if (key == "type_pm") {
          this.formData.count_fee_marketing =
            this.formData.type_pm == "non pm" ? false : true;
        }
        this.rules[index] = { ...rules };
        var check = await checkRules(this.rules[index], this.formData);
        this.formError = check.error;
        return check.success;
      }
    },
    getPenomoran() {
      var params = {
        id_divisi: this.formData.id_divisi,
        id_tipe: "19",
      };
      get_DepartementPenomoran(params, (res) => {
        // this.optionNumbering = res.list;
        this.formData.id_penomoran = "";
        var data = [];
        for (const key in res.list) {
          if (Object.hasOwnProperty.call(res.list, key)) {
            const element = res.list[key];
            if (key == 0) {
              this.formData.id_penomoran = element.id;
            }
            data.push({
              id: element.id,
              text: element.nama,
              no: element.number,
              type: "",
              sub_level: 0,
              disabled: false,
            });
          }
        }

        this.optionNumbering = data;
      });
    },
    moneyToInt(string) {
      return parseInt(string.toString().split(".").join(""));
    },
    postData() {
      this.formData.lokasi = this.listLocation;
      this.formData.listpe = this.listPE;
      this.formData.report = [];
      this.formData.anggota = [];
      this.formData.pengeluaran = [];
      this.formData.deleteAnggota = [];
      var formDataFix = { ...this.formData };
      formDataFix.count_fee_marketing = formDataFix.count_fee_marketing
        ? "ya"
        : "tidak";
      if (formDataFix.management_fee_satuan == "%") {
        formDataFix.management_fee_value = this.moneyToInt(
          this.formData.management_fee
        );
        formDataFix.management_fee = Math.ceil(
          (this.moneyToInt(this.formData.nilai_kontrak) *
            this.moneyToInt(this.formData.management_fee)) /
            100
        );
      } else {
        formDataFix.management_fee_value = this.moneyToInt(
          this.formData.management_fee
        );
      }

      if (formDataFix.diskon_satuan == "%") {
        formDataFix.diskon_value = this.moneyToInt(this.formData.diskon);
        formDataFix.diskon = Math.ceil(
          (this.moneyToInt(this.formData.nilai_kontrak) *
            this.moneyToInt(this.formData.diskon)) /
            100
        );
      } else {
        formDataFix.diskon_value = this.moneyToInt(this.formData.diskon);
      }

      formDataFix.approval_person = JSON.stringify(
        this.formData.approval_person
      );

        this.isSubmit = true;
        post_ProjectSave(
          formDataFix,
          (res) => {
            this.isSubmit = false;
            var msg =
              res.response_code === 201
                ? "Data project berhasil ditambahkan"
                : "Data project berhasil diperbarui";
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: msg,
              onSubmit: () => {
                this.$router.push({
                  name: "DataProject",
                });
              },
            });
          },
          () => {
            this.isSubmit = false;
            showAlert(this.$swal, {
              title: "GAGAL!",
              msg: "Terjadi kesalahan, silakan ulangi kembali",
              showCancelButton: true,
              showConfirmButton: false,
            });
          }
        );
    },
    async onSubmit() {
      // for (const key in this.rules) {
      //   if (Object.hasOwnProperty.call(this.rules, key)) {
      //     this.rules[key].changed = true;
      //   }
      // }
      // var check = await this.formChange("");
      // if (true) {
        if (this.listLocation.length > 0) {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: this.formData.kode
              ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
              : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
            showCancelButton: true,
            cancelButtonText: "Batal",
            confirmButtonText: "Ya, Lanjutkan",
            onSubmit: () => {
              this.postData();
            },
          });
        } else {
            showAlert(this.$swal, {
              title: "PERHATIAN!",
              msg: "Silakan masukan data lokasi project terlebih dahulu",
              cancelButtonText: "Tutup",
              showCancelButton: true,
              showConfirmButton: false,
            });
        }
      // }
    },
    showInvoiceProject(ev) {
      this.id_invoice = ev;
      this.showInvoice = true;
    },

    //import
    downloadFormat() {
      window.open(DATA.FORMAT_PE(), "_blank");
    },
    changeFile($event) {
      if ($event.target.files.length > 0) {
        var files = $event.target.files[0];
        var nameFile = files.name.split(".");
        var mimeType = files.type;
        if (
          mimeType ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
          nameFile[nameFile.length - 1]
        ) {
          // this.formImport.file = files;
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Apakah anda yakin akan mengimpor file ini?",
            confirmButtonText: "Ya, Lanjutkan",
            cancelButtonText: "Batal",
            showConfirmButton: true,
            showCancelButton: true,
            onSubmit: async () => {
              var sheetName = await readSheetNames(files);
              var nilai = 0;
              var listData = [];
              this.onUpload = true;
              for (const keySheet in sheetName) {
                if (Object.hasOwnProperty.call(sheetName, keySheet)) {
                  const nameSheet = sheetName[keySheet];
                  await readXlsxFile(files, {
                    schema: this.schemaPE,
                    sheet: nameSheet,
                  }).then((row) => {
                    var error = row.errors.length;
                    if (error > 0) {
                      this.onUpload = false;
                      listData = [];
                      showAlert(this.$swal, {
                        title: "GAGAL!",
                        msg: "Kolom terdapat yang kosong, pastikan semua kolom terisi(NOMOR ITEM, NAMA ITEM, QTY, SATUAN QTY, FRQ, SATUAN_FRQ, DAN UNIT COST)",
                        showCancelButton: true,
                        showConfirmButton: false,
                      });
                    } else {
                      this.onUpload = true;
                      for (const key in row.rows) {
                        if (Object.hasOwnProperty.call(row.rows, key)) {
                          const element = row.rows[key];
                          var nilaiItem = parseInt(element.unit_cost);
                          var qty = parseInt(element.qty)
                            ? parseInt(element.qty)
                            : 1;
                          var frq = parseInt(element.qty_frq)
                            ? parseInt(element.qty_frq)
                            : 1;

                          nilaiItem = nilaiItem * qty;
                          if (element.qty_frq) {
                            nilaiItem = nilaiItem * frq;
                          }
                          if (element.volume) {
                            var volume = parseInt(element.volume)
                              ? parseInt(element.volume)
                              : 1;
                            nilaiItem = nilaiItem * volume;
                          }
                          var ex = element.id.includes(",")
                            ? element.id.split(",")
                            : element.id.split(".");
                          var data = {
                            id: element.id,
                            nama: element.nama,
                            qty: qty,
                            satuan_qty: element.satuan_qty,
                            qty_frq: frq,
                            satuan_frq: element.satuan_frq,
                            volume: element.volume,
                            satuan_volume: element.satuan_volume,
                            unit_cost: element.unit_cost,
                            include_pph: element.include_pph,
                            pph: element.pph,
                            remarks: element.remarks,
                            nilai: nilaiItem,
                            sub_level: ex.length <= 3 ? ex.length - 1 : 2,
                          };
                          if (nilaiItem) {
                            nilai += nilaiItem;
                          }
                          listData.push(data);
                        }
                      }
                    }
                  });
                }
              }
              if (listData.length > 0) {
                if (
                  !this.formData.nilai_kontrak ||
                  this.formData.nilai_kontrak == "0"
                ) {
                  this.formData.nilai_kontrak = this.formatMoney(nilai);
                }
                for (const key in listData) {
                  if (Object.hasOwnProperty.call(listData, key)) {
                    const element = listData[key];
                    var search = new RegExp(element.id, "i");
                    var hasChild = listData.filter(
                      (e) => search.test(e.id) && e.id != element.id
                    );
                    listData[key].hasChild = hasChild.length > 0 ? 1 : 0;
                  }
                }
                this.listPE = listData;
                this.onUpload = false;
              } else {
                this.onUpload = false;
              }
            },
            onCancel: () => {
              $event.target.value = "";
            },
            onDismiss: () => {
              $event.target.value = "";
            },
          });
        } else {
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Format file tidak didukung, pastikan file berformat .xlsx sebelum mengimport data",
            showConfirmButton: false,
          });
        }
      }
    },

    //form-step
    async toStep(next = "") {
      if (this.stepActive == 1 && next == 2) {
        for (const key in this.rules[0]) {
          if (Object.hasOwnProperty.call(this.rules[0], key)) {
            this.rules[0][key].changed = true;
          }
        }
        var check = await checkRules(this.rules[0], this.formData);
        this.formError = check.error;
        if (check.success) {
          this.stepActive = next;
        }
      } else if (this.stepActive == 2 && next == 3) {
        for (const key in this.rules[1]) {
          if (Object.hasOwnProperty.call(this.rules[1], key)) {
            this.rules[1][key].changed = true;
          }
        }
        var checkKeuangan = await checkRules(this.rules[1], this.formData);
        this.formError = checkKeuangan.error;
        if (checkKeuangan.success) {
          this.stepActive = next;
        }
      } else if (this.stepActive == 3 && next == "") {
        this.onSubmit();
      } else {
        this.stepActive = next;
      }
    },
    checkRabSatuanQty(qty, satuan) {
      if (qty && satuan) {
        return qty + " " + satuan;
      } else if (qty) {
        return qty;
      } else if (satuan) {
        return "0 " + satuan;
      } else {
        return "-";
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
  border-radius: 5px;
  border: none;
}

.nav-pills .nav-item .nav-link.active[data-v-161fae0a]:after {
  border-radius: 5px !important;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

/* Modal Add Member */

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid #d0d0d0;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #fff;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"]:checked {
  background-color: #68caf8 !important;
  border: none;
}

#modalAddMember .modal-content {
  overflow-y: auto;
}

#modalAddMember .modal-footer {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  padding: 10px 52px;
}

#modalAddMember .modal-body {
  padding-bottom: 60px;
}

#modalAddMember .modal-dialog {
  max-width: 800px;
}

#modalAddMember .modal-content {
  height: 100%;
  border-radius: 0px;
}

/* #modalAddMember .btn-save {
        mar
    } */

.modal-body .form-group:last-child {
  position: relative;
}

.title-item {
  position: relative;
}

.card-modal {
  padding: 20px 36px;
}

#addAddress .card-modal {
  padding: 20px 10px;
}

.btn-add-title {
  background-color: #d7ffea;
  color: #3cb778;
  font-weight: 500;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.modal-dialog {
  max-width: 500px;
}

.modal-content {
  height: 100%;
  border-radius: 0px;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.modal-content {
  overflow-y: auto;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

/* End Modal Add Member */

/* detail invoice */
.btn-lampiran {
  background-color: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
}

#modalAddPayment .btn-save {
  background-color: transparent !important;
  color: #68caf8 !important;
  border: 1px solid #68caf8 !important;
  font-weight: 400;
  font-size: 15px;
  padding: 10px 0px;
}

#modalAddPayment .btn-save:hover {
  background-color: #68caf8 !important;
  color: #fff !important;
}

.card-detail-invoice {
  background: linear-gradient(103.2deg, #6ec0fe 0%, #4df3dd 100%);
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  line-height: 30px;
}

#modalPrintInvoice .modal-dialog {
  max-width: 1162px;
}

.modal .table td {
  height: 44px !important;
}

.btn-add-payment {
  background-color: #d7ffea;
  color: #3cb778;
  padding: 5px 10px;
  font-weight: 500;
  margin-right: 15px;
}

.btn-print-invoice {
  background-color: #f9ffd7;
  color: #b9b201;
  padding: 5px 10px;
  font-weight: 500;
}

.address-company {
  font-size: 12px;
  color: #263238;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.noEstimated {
  font-size: 12px;
  color: #898989;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.card-total {
  display: flex;
}

.payment-invoice {
  padding: 0px 46px;
}

.sub-total {
  margin-right: 50px;
  font-size: 14px;
}

.amount {
  font-size: 14px;
}

.name-company {
  font-size: 18px;
  font-weight: 500;
}

.detail-company .value {
  color: #263238;
  font-size: 16px;
  font-weight: 400;
}

.detail-company .text {
  color: #cdcdcd;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.title-invoice {
  font-weight: 700;
  font-size: 50px;
  background: linear-gradient(102.18deg, #6ec0fe -17.12%, #61efe7 95.5%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.total-payment {
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
}

/* .text {
        margin-top: 10px;
    } */

.status-invoice-paid {
  background: #d7ffea;
  color: #3cb778;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 50px;
  border: none;
  cursor: default;
  border-radius: 5px;
}

.btn-export {
  background: #e4efff;
  color: #68caf8;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
}

.modal .btn-print-invoice {
  background: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 23px;
  border-radius: 5px;
  border: none;
}

.btn-invoice {
  background: #fff3c7;
  color: #f8ce3a;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

/* End Detail Invoice */

/* Card Address */

.card-address {
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  padding: 20px;
  line-height: 35px;
  position: relative;
  margin-bottom: 20px;
}

.card-address .text-address {
  color: #263238;
}

.card-address .company-title {
  color: #263238;
  font-weight: 600;
}

/* End Card Address */

.btn-edit-address {
  color: #f8ce3a;
  margin-right: 10px;
}

.btn-edit-address:hover {
  background: #f8ce3a;
  color: #fff;
}

.btn-delete-address {
  color: #ff3d3d;
}

.btn-delete-address:hover {
  background: #ff3d3d;
  color: #fff;
}

.btn-edit-address:hover svg path {
  fill: #fff !important;
}

.btn-delete-address:hover svg path {
  fill: #fff !important;
}

.group-btn svg {
  margin-right: 6px;
  vertical-align: middle;
}

.group-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.modal.right.fade .modal-dialog {
  width: 800px;
}

.depth2 {
  padding-left: 45px !important;
}

.depth3 {
  padding-left: 65px !important;
}

.btn-import {
  background: #00c8bc;
  color: white;
  font-weight: 400;
  border-radius: 3px;
}
.btn-import:hover {
  background: #01a197;
}
.btn-download {
  background: #ffa736;
  border-radius: 3px;
  color: white;
  font-weight: 400;
}

.btn-download:hover {
  background: #e49124;
}
</style>
